import React from 'react';

const Card = ({ el, openModal }) => {
    return (
    <div className='card' onClick={(e) => openModal(el.slides, el.id)}>
      <h3>{el.title}</h3>
      <div className='card__footer'>
        <p>
          Autorzy: <span>{el.author}</span>
        </p>
        <p>Nazwa jednostki: {el.description}</p>
      </div>
    </div>
  );
};

export default Card;
