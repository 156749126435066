import react, {useState} from "react";
import './App.css';
import logo from './assets/img/logo.png';
import Card from './components/Card';
import data from './data/data';
import SwiperModal from "./components/SwiperModal";

function App() {
    const [swiperLinks, setSwiperLinks] = useState([])
    const [slideIndex, setSlideIndex] = useState(null);

    const swiperHandler = (links, index) => {
        setSwiperLinks(links);
        setSlideIndex(index);
    }

    return (
        <div className='app'>
            <header className='app__header'>
                <img src={logo} alt=''/>
            </header>
            <div className='main'>

                {data.map((el, i) => <Card key={i} el={el} openModal={swiperHandler}/>)}
            </div>

            <footer>
                <p>SC-PL-CARFILZOMI-00318 kwiecień 2022</p>
            </footer>
            {swiperLinks.length && <SwiperModal links={swiperLinks} closeModal={swiperHandler} id={slideIndex}/> }
        </div>
    );
}

export default App;
