const data = [
    {
        id: 1,
        title: 'Opis przypadku pacjenta chorującego na szpiczaka plazmocytowego leczonego karfilzomibem w II-giej linii leczenia',
        author: 'lek.med. Dariusz Kumorek',
        description: 'Oddział Hematoonkologii i Transplantacji Szpiku SzSPOO w Brzozowie',
        slides: ['MM-1-Kumorek.jpg', 'MM-1-Kumorek2.jpg', 'MM-1-Kumorek3.jpg']
    },
    {
        id: 2,
        title: 'Przypadek pacjenta ze szpiczakiem plazmocytowym, leczonego karfilzomibem, w schemacie Kd w III linii leczenia',
        author: 'lek. med. Marcin Fejklowicz',
        description: 'SP ZOZ Zespół Szpitali Miejskich, Oddział Kliniczny Hematologii i Profilaktyki Chorób Nowotworowych - Chorzów',
        slides: ['MM-7-Fejklowicz.jpg', 'MM-7-Fejklowicz2.jpg', 'MM-7-Fejklowicz3.jpg']
    },
    {
        id: 3,
        title: 'Opis przypadku pacjenta chorującego na szpiczaka plazmocytowego leczonego karfilzomibem w II linii leczenia',
        author: 'lek. med. Paweł Steckiewicz',
        description: 'Klinika Hematologii i Transplantacji Szpiku. Świętokrzyskie Centrum Onkologii w Kielcach',
        slides: ['MM-4-Steckiewicz.jpg', 'MM-4-Steckiewicz2.jpg']
    },
    {
        id: 4,
        title: 'Pacjentka ze szpiczakiem plazmocytowym, leczona karfilzomibem w schemacie Kd, w pierwszym nawrocie.',
        author: 'lek. med. Agata Tyczyńska',
        description: 'Katedra i Klinika Hematologii i Transplantologii , Gdański Uniwersytet Medyczny, Uniwersyteckie Centrum Kliniczne',
        slides: ['MM-10-Tyczynska.jpg', 'MM-10-Tyczynska2.jpg']

    },
    {
        id: 5,
        title: 'Opis przypadku pacjenta chorującego na szpiczaka plazmocytowego leczonego karfilzomibem.',
        author: 'lek. med. Urszula Kurnyta-Oleksy / dr n med. Szymon Fornagiel',
        description: 'Szpital Specjalistyczny w Nowym Sączu, Oddział Hematologiczny',
        slides: ['MM-2-Kurnyta-Oleksy.jpg', 'MM-2-Kurnyta-Oleksy2.jpg']
    },
    {
        id: 6,
        title: 'Przypadek pacjenta ze szpiczakiem plazmocytowym wysokiego ryzyka leczonego długotrwale karfilzomibem.',
        author: 'lek.med. Ryszard Wichary',
        description: 'Oddział Hematologii i Transplantacji Szpiku SPSKM SUM w Katowicach',
        slides: ['MM-8-Wichary.jpg', 'MM-8-Wichary2.jpg']
    },
    {
        id: 7,
        title: 'Skuteczne leczenie nawrotowego szpiczaka niewydzielającego z zastosowaniem karfilzomibu, deksametazonu i podskórnego daratumumabu.',
        author: ' dr n. med. Mateusz Ziarkiewicz',
        description: 'Klinika Hematologii, Transplantologii i Chorób Wewnętrznych, Warszawski Uniwersytet Medyczny',
        slides: ['MM-5-Ziarkiewicz.jpg', 'MM-5-Ziarkiewicz2.jpg', 'MM-5-Ziarkiewicz3.jpg']
    },

    {
        id: 8,
        title: 'Przypadek pacjenta z opornym szpiczakiem plazmocytowym, z szybkim włączeniem II linii leczenia w schemacie KD.',
        author: 'lek. med. Monika Mordak-Domagała',
        description: 'Centrum Hematologiczno -Transplantacyjne, Wrocław Grabiszyńska 105',
        slides: ['MM-11-Mordak.jpg', 'MM-11-Mordak2.jpg']
    },
    {
        id: 9,
        title: 'Opis przypadku szpiczaka plazmocytowego leczonego karfilzomibem',
        author: 'prof. dr hab. n. med Artur Jurczyszyn',
        description: 'Ośrodek Leczenia Dyskrazji Plazmocytowych Katedra Hematologii Wydział Lekarski UJ CM w Krakowie',
        slides: ['MM-3-Jurczyszyn.jpg', 'MM-3-Jurczyszyn2.jpg']
    },
    {
        id: 10,
        title: 'Opis przypadku pacjenta ze szpiczakiem plazmocytowym, leczonego karfilzomibem.',
        author: 'prof. dr hab. Aleksandra Butrym',
        description: 'Oddział Hematologiczny, Specjalistyczny Szpital im. dra A. Sokołowskiego w Wałbrzychu',
        slides: ['MM-9-Butrym.jpg', 'MM-9-Butrym2.jpg']
    },
    {
        id: 11,
        title: 'Opis przypadku zastosowania schematu Kd w II linii terapii, u pacjenta z rozpoznaniem szpiczaka plazmocytowego wysokiego ryzyka.',
        author: 'lek. med. Magdalena Olszewska-Szopa',
        description: 'Klinika Hematologii, Nowotworów Krwi i Transplantacji Szpiku - Wrocław',
        slides: ['MM-6-Olszewska-Szopa.jpg', 'MM-6-Olszewska-Szopa2.jpg']
    },
    {
        id: 12,
        title: 'Pacjent ze szpiczakiem plazmocytowym - długotrwała terapia karfilzomibemw schemacie Kd',
        author: 'dr hab. med. Dominik Dytfeld',
        description: 'Katedra i Kliniki Hematologii i Transplantacji Szpiku UM w Poznaniu',
        slides: ['MM-12-Dytfeld.jpg', 'MM-12-Dytfeld2.jpg']
    },

];

export default data;
