import React from 'react'
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import closeButton from '../assets/img/closeButton.svg'

const SwiperModal = ({links, id, closeModal}) => {
    return <div
        className='modal'
    >
        <div
            className='modal__content'
        >
            <img
                className='modal__content-btn'
                src={closeButton}
                onClick={() => closeModal([], null)}
                alt=''/>
            <Swiper navigation={true} modules={[Navigation]} className="mySwiper">

                {
                    links.map((link, i) =>
                        <SwiperSlide
                            key={i}
                        >
                            <div className='modal__content-slideContent'>
                                <img
                                    className='modal__content-slideImg'
                                    src={`./assets/img/slide${id}/${link}`}
                                />
                            </div>
                        </SwiperSlide>
                    )
                }
            </Swiper>
        </div>
    </div>
}

export default SwiperModal;

